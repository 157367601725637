import { createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";

// A custom theme for this app
const theme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: "#000",
      // default: "file(Background.png)",
      // paper:
      // paper: "rgb(248, 248, 255)",
    },
    primary: {
      main: "#008800",
    },
    secondary: {
      main: "#19857b",
    },
    text: { primary: "#fff", secondary: "#0f0" },
    error: {
      main: red.A400,
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: { backgroundColor: "rgba(0,0,0,1)", borderRadius: 20 },
      },
    },
    // MuiMenuList: {
    //   styleOverrides: {
    //     padding: { background: "#000" },
    //     dense: { background: "#000" },
    //     root: { background: "#000" },
    //   },
    // },
    // MuiMenuItem: {
    //   styleOverrides: {
    //     root: { background: "#000" },
    //   },
    // },
  },
});

export default theme;
