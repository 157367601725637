import {
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React, { useState } from "react";
import {
  ContractAccessControl,
  ContractOptionsFeatures,
  ContractOptionsType,
  ContractUpgradeable,
  DefaultContractOptions,
} from "./types/contractOptions";
import styled from "@emotion/styled";

export const ContractOptions = (props: {
  options: ContractOptionsType;
  setOptions: (o: ContractOptionsType) => void;
}) => {
  const simpleFields: (keyof ContractOptionsType)[] = [
    "name",
    "symbol",
    "premint",
  ];

  const features: (keyof ContractOptionsFeatures)[] = [
    "mintable",
    "burnable",
    "pausable",
    "permit",
    "votes",
    "flashMinting",
  ];

  return (
    <>
      <Card>
        {/* <CardHeader title={""} /> */}
        <CardContent>
          <FormWrapper>
            {simpleFields.map((i) => (
              <FormControl key={"field_" + i}>
                <InputLabel>{i}</InputLabel>
                <Input
                  value={props.options[i]}
                  onChange={(v) =>
                    props.setOptions({ ...props.options, [i]: v.target.value })
                  }
                />
              </FormControl>
            ))}
          </FormWrapper>
        </CardContent>
      </Card>
      <Card>
        {/* <CardHeader title={"Features"} /> */}
        <CardContent>
          <Grid container>
            {features.map((i) => (
              <Grid item xs={5}>
                <FormControlLabel
                  label={i}
                  key={"field_features_" + i}
                  style={{ minWidth: 240 }}
                  control={
                    <Checkbox
                      checked={props.options.features[i]}
                      onChange={(v) =>
                        props.setOptions({
                          ...props.options,
                          features: {
                            ...props.options.features,
                            [i]: v.target.checked,
                          },
                        })
                      }
                    />
                  }
                ></FormControlLabel>
              </Grid>
            ))}
          </Grid>
          <Grid container gap={1} padding={0} marginTop={2} marginLeft={-2}>
            <Grid item xs={6}>
              <FormControlLabel
                label={"Access control"}
                labelPlacement={"top"}
                style={{ width: "100%" }}
                control={
                  <Select
                    size="small"
                    onChange={(v) =>
                      props.setOptions({
                        ...props.options,
                        accessControl: v.target.value as ContractAccessControl,
                      })
                    }
                    value={props.options.accessControl}
                  >
                    <MenuItem value={ContractAccessControl.managed}>
                      Managed
                    </MenuItem>
                    <MenuItem value={ContractAccessControl.ownable}>
                      Ownable
                    </MenuItem>
                    <MenuItem value={ContractAccessControl.roles}>
                      Roles
                    </MenuItem>
                  </Select>
                }
              />
            </Grid>
            <Grid item xs={5}>
              <FormControlLabel
                label={"Upgradeable"}
                labelPlacement={"top"}
                style={{ width: "100%" }}
                control={
                  <Select
                    size="small"
                    onChange={(v) =>
                      props.setOptions({
                        ...props.options,
                        upgradeable: v.target.value as ContractUpgradeable,
                      })
                    }
                    value={props.options.upgradeable}
                  >
                    <MenuItem value={ContractUpgradeable.no}>No</MenuItem>
                    <MenuItem value={ContractUpgradeable.transparent}>
                      Transparent
                    </MenuItem>
                    <MenuItem value={ContractUpgradeable.uups}>UUPS</MenuItem>
                  </Select>
                }
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const FormWrapperCheckboxes = styled.div`
  display: flex;
  /* flex-direction: column; */
  gap: 20px;
  flex-wrap: "wrap";
`;

const FormInput = styled.div``;
