import * as React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import ProTip from "./ProTip";
import { TextareaAutosize } from "@mui/material";
import { ContractGen } from "./ContractGen";

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}.
    </Typography>
  );
}

export default function App() {
  return (
    <Container style={{ maxWidth: "100%", padding: "0 5vw" }}>
      <Box sx={{ my: 4 }}>
        {/* <Typography
          variant="h4"
          style={{ marginBottom: 35 }}
          textAlign={"center"}
          component="h1"
        >
          ERC20 Contract generator
        </Typography> */}

        <div style={{ marginTop: 80 }}></div>
        <ContractGen />
        {/* <textarea /> */}
        {/* <ProTip /> */}
        {/* <Copyright /> */}
      </Box>
    </Container>
  );
}
