import { useState } from "react";
import { Button, IconButton, Snackbar } from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";
import React from "react";
import { CopyAll, Opacity } from "@mui/icons-material";
import { buttonBg } from "./buttonBg";

export const CopyButton = (props: { content: string; disabled: boolean }) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
    navigator.clipboard.writeText(props.content);
  };

  return (
    <>
      <Button
        disabled={props.disabled}
        onClick={handleClick}
        color="primary"
        variant="contained"
        style={buttonBg}
      >
        <CopyAll />
        <span style={{ flex: 1, textAlign: "center" }}>Copy to clipboard</span>
      </Button>
      <Snackbar
        message="Copied to clibboard"
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={2000}
        onClose={() => setOpen(false)}
        open={open}
      />
    </>
  );
};
