import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { ContractOptions } from "./ContractOptions";
import {
  ContractOptionsType,
  DefaultContractOptions,
} from "./types/contractOptions";
import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
// import hljsDefineSolidity from 'highlightjs-solidity'

import ShareIcon from "@mui/icons-material/CopyAll";
import { CopyButton } from "./CopyContract";
import {
  Engineering,
  GasMeterSharp,
  InstallDesktop,
  Power,
  PowerInput,
  PowerOff,
  PowerRounded,
  Rocket,
  Start,
} from "@mui/icons-material";
import ReCAPTCHA from "react-google-recaptcha";
import { buttonBg } from "./buttonBg";

// hljsDefineSolidity()

export const ContractGen = (props: {}) => {
  const captchaRef = React.useRef<ReCAPTCHA>(null);
  const [inProgress, setInProgress] = useState(false);
  const [contract, setContract] = useState("Your contract will be here");
  const [options, setOptions] = useState<ContractOptionsType>(
    DefaultContractOptions
  );

  const genContract = async (captha: string) => {
    const res = await fetch(process.env.REACT_APP_BACKEND_URL!, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({ ...options, captha }),
    });
    const json = await res.json();
    setContract(json.contract);
  };

  const handleGenerate = async () => {
    if (!captchaRef.current) {
      console.warn("Missing captcha ref");
      return;
    }
    let res = null;
    setInProgress(true);
    try {
      res = await captchaRef.current
        .executeAsync()
        .then((res) =>
          res === null ? new Error("Missing captcha token") : res
        )
        .catch((e) => e as Error);

      if (res instanceof Error) {
        console.log(res);
      } else {
        await genContract(res);
      }
    } catch (ex) {
      console.log(ex);
    }
    setInProgress(false);
  };

  return (
    <>
      <ReCAPTCHA
        sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY!}
        size="invisible"
        theme="light"
        ref={captchaRef}
      />
      <Stack direction={"row"} spacing={4} flexWrap={"wrap"} gap={5}>
        <div
          style={{
            // maxWidth: 600,
            minWidth: 300,
            display: "flex",
            flex: 1,
            flexDirection: "column",
            gap: 15,
          }}
        >
          <ContractOptions options={options} setOptions={setOptions} />
          <Button
            disabled={inProgress}
            variant="contained"
            onClick={() => handleGenerate()}
            style={buttonBg}
          >
            {!inProgress && <Engineering />}
            {inProgress && <CircularProgress size={18} />}
            <span style={{ flex: 1, textAlign: "center" }}>Generate</span>
          </Button>
          <CopyButton disabled={inProgress} content={contract} />
          <ComingSoonButton />
        </div>
        <Card
          style={{
            width: "100%",
            flex: 5,
            minWidth: 400,
            minHeight: 400,
            // marginTop: 10,
            // marginRight: 40,
            marginLeft: 0,
            background: "url(Background2.png)",
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% 100%",
          }}
        >
          {/* <CardHeader> */}
          {/* </CardHeader> */}
          <CardContent
            style={
              !inProgress
                ? {
                    filter:
                      "grayscale(100%) contrast(35%) brightness(100%) sepia(100%) saturate(680%) hue-rotate(100deg)",
                  }
                : {}
            }
          >
            {inProgress && (
              <div style={{ margin: "auto", width: 500 }}>
                <LoadingVid />
              </div>
            )}
            {!inProgress && (
              <SyntaxHighlighter
                language="solidity"
                style={docco}
                customStyle={{
                  background: "rgba(0,0,0,0)",
                  marginLeft: 20,
                  marginRight: 20,
                }}
              >
                {contract}
              </SyntaxHighlighter>
            )}
          </CardContent>
        </Card>
      </Stack>
      {/* <Typography whiteSpace={"pre-wrap"}>{contract}</Typography> */}
    </>
  );
};

const LoadingVid = () => {
  return (
    <video
      width="500"
      height="500"
      // controls
      loop
      autoPlay
      muted
      style={{ mixBlendMode: "lighten", filter: "blur(.6px)" }}
    >
      <source src="./untitled1.mp4" type="video/mp4" />
    </video>
  );
};

const ComingSoonButton = () => {
  return (
    <Button disabled={true} variant="contained" style={buttonBg}>
      <Rocket />
      <div style={{ width: "100%" }}>
        <div style={{ flex: 1, textAlign: "center", maxWidth: 200 }}>
          Launch your smart contract in 1 click
        </div>
      </div>
      <div
        style={{
          position: "relative",
          width: 80,
          height: 60,
          marginRight: -16,
          marginTop: -6,
          overflow: "hidden",
          // background: "red",
        }}
      >
        <div
          style={{
            textAlign: "center",
            width: 90,
            fontSize: 12,
            fontFamily: "arial",
            rotate: "45deg",
            background: "#777",
            color: "#fff",
            paddingRight: 7,
            marginTop: 10,
          }}
        >
          Soon
        </div>
      </div>
    </Button>
  );
};
