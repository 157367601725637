export enum ContractAccessControl {
  ownable = "ownable",
  roles = "roles",
  managed = "managed",
}
export enum ContractUpgradeable {
  no = "no",
  transparent = "transparent",
  uups = "uups",
}

export type ContractOptionsFeatures = {
  mintable: boolean;
  burnable: boolean;
  pausable: boolean;
  permit: boolean;
  votes: boolean;
  flashMinting: boolean;
};

export type ContractOptionsType = {
  name: string;
  symbol: string;
  premint: number;
  features: {
    mintable: boolean;
    burnable: boolean;
    pausable: boolean;
    permit: boolean;
    votes: boolean;
    flashMinting: boolean;
  };
  accessControl: ContractAccessControl;
  upgradeable: ContractUpgradeable;
};

export const DefaultContractOptions: ContractOptionsType = {
  name: "Name",
  symbol: "NAME",
  premint: 0,
  features: {
    burnable: false,
    flashMinting: false,
    mintable: false,
    pausable: false,
    permit: false,
    votes: false,
  },
  accessControl: ContractAccessControl.ownable,
  upgradeable: ContractUpgradeable.no,
};
